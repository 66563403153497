<template>
  <section class="tender-details main-wrapper">
    <div class="main-content">
      <header-vue />
      <bread-crumbs :breadcrumbItems="breadcrumbItems" />

      <div class="container text-center-mobile-only pt-6 mb-6 pr-6-tablet">
        <div
          class="
            is-flex is-align-items-center
            column
            is-flex-wrap-wrap is-justify-content-center
          "
        >
          <div class="back-arrow pr-2 mr-5">
            <router-link to="/tenders" class="link"
              ><back-arrow-icon
            /></router-link>
          </div>
          <h2
            class="
              main-title
              is-size-3-5-mobile
              has-text-left has-text-centered-mobile
              is-flex-grow-1
            "
          >
            {{ tenderInfo.name }}
          </h2>
          <div class="has-text-right-mobile has-text-left-tablet"></div>
        </div>
      </div>
      <div class="container mb-4 text-center-mobile-only">
        <div class="p-6 p-d-2 shadow-d br-2 mb-6">
          <div
            class="
              tender-details__header
              is-flex is-justify-content-space-between is-align-items-center
              pb-6
            "
          >
            <tnd-user-card :userInfo="tenderInfo.author" />
            <router-link
              :to="{ name: 'request', params: { id: tenderInfo.id } }"
              class="button is-normal main-button purple"
              @click="nextStep('messages')"
            >
              <hammer-icon color="#FFFFFF" style="margin-right: 10px" />
              ПОДАТЬ ЗАЯВКУ
            </router-link>
          </div>
          <b-tabs class="tender-details__body" type="is-toggle" multiline>
            <b-tab-item
              label="Информация"
              headerClass="tender-details__body__tab"
            >
              <tender-info :data="tenderInfo" />
            </b-tab-item>
            <b-tab-item label="Файлы" headerClass="tender-details__body__tab">
              <tender-files :files="tenderInfo.files" />
            </b-tab-item>
            <b-tab-item
              label="Позиции тендера"
              headerClass="tender-details__body__tab"
            >
              <details-positions
                :data="detailsData"
                :columns="deailsPositionsColumns"
              />
            </b-tab-item>
            <b-tab-item
              label="Заявка"
              headerClass="tender-details__body__tab"
            ></b-tab-item>
          </b-tabs>
        </div>
        <div
          class="has-text-centered is-size-5 has-text-weight-medium py-3 mb-5"
        >
          ДРУГИЕ ТЕНДЕРЫ ПО ЭТОМУ ПРОЕКТУ
        </div>
        <div class="p-0 p-d-2 shadow-d br-2" v-if="otherTenders">
          <tnd-table
            :data="otherTenders"
            :columns="tablecolumns"
            v-slot="{ props, column }"
          >
            <div v-if="column.type === 'link'">
              <a :href="props.row[column.field].link">{{
                props.row[column.field].value
              }}</a>
            </div>
            <div v-if="column.type === 'datePeriod'">
              <p>{{ props.row[column.field] }}</p>
            </div>
          </tnd-table>
        </div>
      </div>
    </div>
    <footer-vue />
  </section>
</template>

<script>
import HeaderVue from "@/components/common/Header.vue";
import FooterVue from "@/components/common/Footer.vue";
import BreadCrumbs from "../components/common/BreadCrumbs.vue";
import BackArrowIcon from "../components/icons/BackArrowIcon.vue";
import TndUserCard from "../components/common/TndUserCard.vue";
import HammerIcon from "../components/icons/HammerIcon.vue";
import TenderInfo from "../tenderDetails/TenderInfo.vue";
import TndTable from "../components/common/TndTable.vue";
import TenderFiles from "../tenderDetails/TenderFiles.vue";
import DetailsPositions from "../tenderDetails/DetailsPositions.vue";
import { filterSelects } from "../home/TenderListFull/TenderListData";
export default {
  components: {
    HeaderVue,
    FooterVue,
    BackArrowIcon,
    BreadCrumbs,
    TndUserCard,
    HammerIcon,
    TenderInfo,
    TndTable,
    TenderFiles,
    DetailsPositions,
  },
  name: "TenderDetails",
  data: () => ({
    user: {
      avatar:
        "https://picjumbo.com/wp-content/uploads/alone-with-his-thoughts-1080x720.jpg",
      name: "Пимченко Сергей Викторович",
      email: "sergey@gmail.com",
      phone: "+7 926 968 95 68",
    },
    deailsPositionsColumns: [
      {
        field: "nomenclature",
        label: "НОМЕНКЛАТУРА",
        type: "description",
      },
      {
        field: "measureUnit",
        label: "ЕДИНИЦА ИЗМЕРЕНИЯ",
      },
      {
        field: "quantity",
        label: "КОЛИЧЕСТВО",
      },
    ],
    tablecolumns: [
      {
        field: "name",
        label: "НАИМЕНОВАНИЕ",
        type: "link",
      },
      {
        field: "date",
        label: "ДАТЫ ПРОВЕДЕНИЯ",
        type: "datePeriod",
      },
      {
        field: "category",
        label: "КАТЕГОРИИ",
      },
      {
        field: "budget",
        label: "ПРИМЕРНЫЙ БЮДЖЕТ, РУБ",
      },
      {
        field: "description",
        label: "КРАТКОЕ ОПИСАНИЕ",
      },
    ],
    tableData: [
      {
        name: {
          link: "/",
          value: "Перегородки в Нижнем Новгороде",
        },
        date: "c 09.11.2021 12:00:00 по 10.11.2021 17:30:00",
        category: "Работы",
        budget: "1 000 000 000",
        description:
          "Изготовление и монтаж перегородок между сварочным и малярным учаска ...",
      },
      {
        name: {
          link: "/",
          value: "Перегородки в Нижнем Новгороде",
        },
        date: "c 09.11.2021 12:00:00 по 10.11.2021 17:30:00",
        category: "Работы",
        budget: "1 000 000 000",
        description:
          "Изготовление и монтаж перегородок между сварочным и малярным учаска ...",
      },
      {
        name: {
          link: "/",
          value: "Перегородки в Нижнем Новгороде",
        },
        date: "c 09.11.2021 12:00:00 по 10.11.2021 17:30:00",
        category: "Работы",
        budget: "1 000 000 000",
        description:
          "Изготовление и монтаж перегородок между сварочным и малярным учаска ...",
      },
    ],
  }),
  computed: {
    tenderInfo() {
      return this.$store.getters["tender/tenderInfo"];
    },
    otherTenders() {
      if (this.tenderInfo.other_tenders)
        return Object.keys(this.tenderInfo.other_tenders).map(i => {
          return {
            'name': {
              'link': '/tenders/info/'+i,
              'value': this.tenderInfo.other_tenders[i].name
            },
            'category': this.getCategoryById(this.tenderInfo.other_tenders[i].category_id),
            'date': 'с ' + this.tenderInfo.other_tenders[i].date_start + ' по ' + this.tenderInfo.other_tenders[i].date_end,
            'budget': this.tenderInfo.other_tenders[i].preliminary_cost,
            'description': this.tenderInfo.other_tenders[i].description_short
          }
        })
      else 
        return []
    },
    breadcrumbItems() {
      return [
        {
          title: "Тендеры",
          link: "/tenders",
        },
        {
          title: this.tenderInfo.name,
          link: null,
        },
      ];
    },
    detailsData() {
      return this.$store.getters["tenderRequest/tenderPosTableData"];
    },
  },
  methods: {    
    getCategoryById(id) {
      const category = filterSelects["categories"].filter((item) => {
        return item.id == id;
      });
      if (category.length) return category[0].title;
      return false;
    },
  },
  created() {
    this.$store.dispatch("tender/loadInfo", this.$route.params.id);
  },
};
</script>

<style lang="scss">
@import "../assets/variables";
.tender-details {
  &__header {
    border-bottom: 1px solid $light-grey;

    @media screen and (max-width: 768px) {
      flex-direction: column;

      button {
        margin-top: 1.25em;
      }
    }
  }

  &__body {
    padding-top: 2.31rem;
    ul {
      border: 2px solid $light-light-grey !important;
      border-radius: 5px;
      flex: inherit;
      justify-content: space-evenly;
    }

    &__tab {
      padding: 2px;
      a {
        border: none !important;
      }

      &.is-active a {
        background: linear-gradient(
          94.43deg,
          $purple-bright 0%,
          $purple-dark 100%
        );

        box-shadow: 2px 0.3em 10px rgba(153, 153, 153, 0.1);
        border-radius: 0.3em;
        color: $white;
      }
    }
  }
}
</style>